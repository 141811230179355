
import { defineComponent, computed, watch, onBeforeUnmount } from 'vue'
import { useConfigStore } from '@/modules/mynd-config'
import { useGoogleSignIn } from '@/modules/auth'
import { useFlagsStore } from '@/modules/flags/store'
import { useFeatureFlags } from '@/modules/flags'
import { getConfig } from '@/modules/api/hook'
import * as E from 'fp-ts/lib/Either'
import ModalOverlay from '@/components/Modal/Overlay'
import ToastDisplay from '@/components/Toast'
import LogRocket from 'logrocket'

export default defineComponent({
	name: 'App',
	components: {
		ModalOverlay,
		ToastDisplay,
	},
	setup() {
		const configStore = useConfigStore()
		const config = computed(() => configStore.getters['getConfig'])
		const isMyndConfigReady = computed(() => !!configStore.getters['getConfig'].googleClientId)

		getConfig()().then((configResponse) => {
			E.map((config: any) => {
				configStore.commit('setConfig', config)
				if (config.environment === 'sandbox' || config.environment === 'production') {
					LogRocket.init('ta8rj2/myndmap')
				}
			})(configResponse)
		})
		const { isGapiReady } = useGoogleSignIn()
		const flagStore = useFlagsStore()
		const { startFeatureFlagPolling, stopFeatureFlagPolling, isActiveFeature } =
			useFeatureFlags(flagStore)

		window.addEventListener('contextmenu', (event) => {
			event.preventDefault()
		})

		watch(isMyndConfigReady, (isMyndConfigReady) => {
			if (isMyndConfigReady) {
				// TODO - update this with the normal way tog et feature flags
				isActiveFeature('')
				startFeatureFlagPolling(config)
			}
		})

		onBeforeUnmount(() => {
			stopFeatureFlagPolling()
		})

		return {
			isMyndConfigReady,
			isGapiReady,
		}
	},
})
